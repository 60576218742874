<template>
  <div class="carousel-container">
    <div class="carousel">
      <div class="carousel-inner" :style="carouselStyle">
        <img v-for="(image, index) in images" :key="index" :src="image" class="carousel-image" />
      </div>
    </div>
    <div class="carousel-indicators">
      <span v-for="(image, index) in images" :key="index" @click="goToSlide(index)" 
            :class="{'active': currentIndex === index}" class="indicator"></span>
    </div>
    <div class="carousel-text">
      <div class="container-label">
        {{ $t('carousel_label') }}       </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        'https://www.servrsc.com/resources/bc_group/carousel/1.jpg',
        'https://www.servrsc.com/resources/bc_group/carousel/2.jpg',
        'https://www.servrsc.com/resources/bc_group/carousel/3.jpg',
        'https://www.servrsc.com/resources/bc_group/carousel/4.jpg',
        'https://www.servrsc.com/resources/bc_group/carousel/5.jpg',
        'https://www.servrsc.com/resources/bc_group/carousel/6.jpg',
        'https://www.servrsc.com/resources/bc_group/carousel/7.jpg'
      ],
      currentIndex: 0,
      interval: null
    };
  },
  computed: {
    carouselStyle() {
      return {
        transform: `translateX(-${this.currentIndex * 100}%)`
      };
    }
  },
  methods: {
    goToSlide(index) {
      this.currentIndex = index;
    },
    startAutoSlide() {
      this.interval = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.images.length;
      }, 3000);
    }
  },
  mounted() {
    this.startAutoSlide();
  },
  beforeUnmount() {
    clearInterval(this.interval);
  }
};
</script>

<style scoped>
.carousel-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.carousel {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.carousel-image {
  width: 100%;
  height: 100vh;
  flex: 0 0 100%;
  object-fit: cover;
}

.carousel-indicators {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.indicator {
  width: 12px;
  height: 12px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease;
}

.indicator.active {
  background: white;
  transform: scale(1.2);
}

.carousel-text {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  text-align: center;
  background: rgba(2, 56, 89, 0.7);
  padding: 15px;
  color: white;
  font-size: 20px;
}

.container-label {
  max-width: 80%;
  margin: 0 auto;
}
</style>
