import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
    {
    path: '/privacy_notice',
    name: 'Aviso de privacidad',
    component: ()=> import('@/views/Privacy.vue')
  },
  {
    path: '/cookies',
    name: 'Uso de cookies',
    component: ()=> import('@/views/Cookies.vue')
  }
  // {
  //   path: '/:pathMatch(.*)*', // Ruta comodín para rutas no existentes
  //   redirect: { name: 'home' }
  // }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
