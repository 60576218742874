// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import { VCalendar } from 'vuetify/labs/VCalendar'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import DateFnsAdapter from '@date-io/date-fns'
import { es } from 'vuetify/locale'

export default createVuetify({
  components: {
    VCalendar,
  },
  locale: {
    defaultLocale: 'es',
    messages: { es }, // Asegúrate de que la clave de idioma esté aquí
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: 'bcTheme',
    themes: {
      bcTheme: {
        dark: false,
        variables: {},
        colors: {
          background: '#262626',
          surface: '#ffffff',
          primary: '#023859',          
          'primary-lighten-a':'#0378a6',
          'primary-lighten-b': '#0388a6',
          'primary-lighten-c': '#55c1d9',
          active: '#03a1fe',
          secondary: '#5aaff7',
          tertiary: '#3d75a5',
          darken: '#000000',
          error: '#CF6679',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        },
      },
    },
  },
});
