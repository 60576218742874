<template>
    <v-container class="pa-4 text-center">
        <v-row align="center" class="fill-height" justify="center">
            <template v-for="(item, i) in items" :key="i">
                <v-col cols="12" md="4">
                    <v-hover v-slot="{ isHovering, props }">
                        <v-card :class="{ 'on-hover': isHovering }" :elevation="isHovering ? 12 : 2" v-bind="props" @click="loadPdf(item.pdf)">
                            <v-img :src="item.img" height="225px" cover>
                                <v-card-title class="text-h5 text-white font-weight-bold d-flex flex-column">
                                    <p class="mt-1">
                                        {{ item.title }}
                                    </p>

                                    <div>
                                        <p class="ma-0 text-body-1 font-weight-bold">
                                            {{ item.text }}
                                        </p>
                                        <p class="text-caption font-weight-medium">
                                            {{ item.subtext }}
                                        </p>
                                    </div>
                                </v-card-title>
                                <div class="container.btns d-flex justify-center">             
                                    <v-btn size="60px" :color="transparent" rounded v-show="isHovering" class="btns-pdf"
                                        :class="{ 'show-btns': isHovering }" @click="loadPdf(item.pdf)">                                     
                                        <v-icon color="white" size="50px">mdi mdi-cloud-download</v-icon>                                       
                                    </v-btn>
                                </div>
                            </v-img>
                        </v-card>
                    </v-hover>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>
<script>
import { usePdfStore } from '@/stores/pdf';
export default {
    props: {
        lng: {
            type: String,
            default: '-'
        },
    },
    watch: {
        lng: {
            handler() {
                this.setList();
            },
            immediate: true
        }
    },
    data: () => ({
        icons: ['mdi mdi-cloud-download'],
        items: [],
        transparent: 'rgba(255, 255, 255, 0)',
    }),
    methods: {
        openPdf(base64String) {
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
        },
        loadPdf(pdf) {
            const pdf_store = usePdfStore()
            pdf_store.getPdf(pdf).then((rs) => {
                if (rs == 200) {
                    this.openPdf(pdf_store.pdfFile.data)
                }
            }, (e) => { console.error(e) })
        },
        setList(){
         this.items = [
            {
                title: this.$t('btn_brochure'),
                text: ``,
                subtext: '',
                pdf: this.$t('pdf_1'),
                img: 'https://www.servrsc.com/resources/bc_group/images/4.jpg',
            },
            {
                title: this.$t('btn_invest'),
                text: ``,
                subtext: '',
                pdf: this.$t('pdf_2'),
                img: 'https://www.servrsc.com/resources/bc_group/images/2.jpg',
            },
            {
                title: this.$t('btn_nofinance'),
                text: ``,
                subtext: '',
                pdf: this.$t('pdf_3'),
                img: 'https://www.servrsc.com/resources/bc_group/images/3.jpg',
            },
        ]
        }
    }
}
</script>
<style scoped>
.v-card {
    transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
    opacity: 0.6;
}
 /* 
.show-btns {
   color: rgba(255, 255, 255, 1) !important; */

    /* font-size: 20px !important; 
}*/

.container.btns {
    position: relative;
    width: 100%;
}

.btns-pdf {
    position: absolute;
    bottom: 15px;
}
</style>