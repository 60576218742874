<template>
  <footer class="footer">
    <div class="footer__main footer__main_mod-a parallax-bg parallax-dark">
      <ul class="bg-slideshow">
        <li>
          <div class="bg-footer bg-slide"></div>
        </li>
      </ul>
      <div class="parallax__inner">
        <div class="container">
          <v-row align="center" no-gutters>
            <v-col cols="12" md="12">
              <a href="home.html" class="footer__logo"><img style="width: 200px;" :src="logo" alt="Logo"
                  class="img-responsive center-block invert-colors " /></a>
            </v-col>

            <v-col cols="12" md="6">
              <div class="footer-main-section footer-main-section_left">
                <div class="footer__contact footer__contact_lg">993 402 7847</div>
                <div class="footer__contact">Email: contacto@bc-group.com.mx</div>
                <div class="footer__contact">inversion@bc-group.com.mx</div>
                <div class="footer__contact">Calle Sabancuy 102 Colonia Jose Colomo,
                  CP 86100, Villahermosa Tabasco.
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="footer-main-section footer-main-section_right">
                <div class="footer-form__info">Recibe las noticias, campañas, únete a nosotros.</div>
                <form class="footer-form">
                  <v-text-field style="color: #ffff" color="white" dark block density="compact" label="Email"
                    model="contact.email" variant="outlined" hide-details append-icon="mdi mdi-send"
                    @click:append="suscribeme"></v-text-field>
                  <!-- <button class="footer-form__btn"><i class="icon pe-7s-mail"></i></button> -->
                </form>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">

            </v-col>
          </v-row>
        </div>
        <div class="container">
          <v-row class="px-4">
            <v-col class="px-5" cols="12" md="4">
              <section class="footer-section">
                <h3 class="footer-section__title">Links</h3>
                <ul class="footer-section__list list list-mark-4">
                  <li><a href="post-1.html">Mi cuenta web access</a></li>
                  <!-- <li><a href="post-1.html">Seguridad Fisica a instalaciones</a></li>
                  <li><a href="post-1.html">Seguridad Industrial</a></li>
                  <li><a href="post-1.html">Seguridad en Eventos Públicos</a></li>
                  <li><a href="post-1.html">Monitoreo Remoto</a></li>
                  <li><a href="post-1.html">Protección V.I.P</a></li>
                  <li><a href="post-1.html">Control de Acceso</a></li>
                  <li><a href="post-1.html">Emergencias y Protocolos</a></li>
                  <li><a href="post-1.html">Análisis de Riesgos</a></li>
                  <li><a href="post-1.html">Consultoría en Seguridad</a></li>
                  <li><a href="post-1.html">CCTV</a></li> -->
                </ul>
              </section>
            </v-col>
            <v-col class="px-5" cols="12" md="4">
              <section class="footer-section">
                <h3 class="footer-section__title">Legales</h3>
                <ul class="footer-section__list list list-mark-4">
                  <li><a href="/privacy_notice">Aviso de privacidad</a></li>
                  <li><a href="post-1.html">Terminos & Condiciones</a></li>
                  <li><a href="post-1.html">Baja de cuenta</a></li>
                  <li><a href="/cookies">Cookies</a></li>
                </ul>
              </section>
            </v-col>
            <v-col class="px-5" cols="12" md="4">
              <section class="footer-section">
                <h3 class="footer-section__title">Siguenos en nuestras redes</h3>
                <ul class="footer-section__list list list-mark-4">
                  <li>
                    <v-avatar>
                      <v-img alt="flag"
                        :src="require('@/assets/svg/face.svg')"></v-img>
                    </v-avatar>
                    <a href="https://www.facebook.com/share/1FsStrTgCY/?mibextid=wwXIfr" target="_blank">Facebook</a></li>
                  <li>
                    <v-avatar>
                      <v-img alt="flag"
                        :src="require('@/assets/svg/instagram.svg')"></v-img>
                    </v-avatar>
                    <a href="https://www.instagram.com/bcgroupmx_?igsh=ZDAzemtuNTNveWhu&utm_source=qr" target="_blank">Instagram</a>
                  </li>
                  <li><v-avatar>
                      <v-img alt="flag"
                        :src="require('@/assets/svg/tiktok.svg')"></v-img>
                    </v-avatar>
                    <a href="https://www.tiktok.com/@bc.group.mx?_t=ZM-8u3JMoWp4eT&_r=1" target="_blank">Tiktok</a></li>
                  <li>
                    <v-avatar>
                      <v-img alt="flag"
                        :src="require('@/assets/svg/in.svg')"></v-img>
                    </v-avatar>
                    <a
                      href="https://www.linkedin.com/in/bc-group-mx-54ba97351?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank">Linkedin</a>
                  </li>
                  <li>
                    <v-avatar>
                      <v-img alt="flag"
                        :src="require('@/assets/svg/yt.svg')"></v-img>
                    </v-avatar>
                    <a href="https://www.youtube.com/channel/UCD-dyDlC5G3yaR0pNiJL23g" target="_blank">YouTube</a>
                  </li>
                </ul>
              </section>
            </v-col>
            <!-- <v-col cols="12" md="7">
                  <section class="footer-section">
                    <h3 class="footer-section__title">Legales</h3>
                    <div class="footer-social-net"><i class="footer-social-net__icon fa fa-twitter color-primary"></i>
                      <div class="footer-social-net__inner">
                        <div class="footer-social-net__text">Lorem ipsum dolor sit amet elit sed do eiusmod temp incididunt ut labore et dolore magna aliqua.</div>
                        <time datetime="2012-10-27 15:20" class="footer-social-net__time">5 minutes ago</time>
                      </div>
                    </div>
                    <div class="footer-social-net"><i class="footer-social-net__icon fa fa-twitter color-primary"></i>
                      <div class="footer-social-net__inner">
                        <div class="footer-social-net__text">Ellamco laboris nisi ut aliquip ex ea comodo consequat Duis aute irure dolor in reprehenderit.</div>
                        <time datetime="2012-10-27 15:20" class="footer-social-net__time">5 minutes ago</time>
                      </div>
                    </div>
                  </section>
                </v-col> -->
          </v-row>

        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 text-white">Copyrights 2025_<a href="/" class="copyright__link"> BC Group</a>_All rights
            reserved.</div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import 'animate.css';
import Swal from 'sweetalert2'
import logo from '@/assets/logo.png'
export default {
  data: () => ({
    logo: logo,
  }),
  methods: {
    suscribeme() {
      console.log("suscribir")
    }
  }
}
</script>
<style scoped>
footer {
  display: block;
}

.bg-footer {
  background-image: url("../../assets/media/components/footer/6.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.center-block {
  display: block;
  /* Hace que la imagen sea un bloque para aplicar el margen automático */
  margin-left: auto;
  margin-right: auto;
}

.invert-colors {
  filter: invert(1) grayscale(1);
  /* Invierte los colores y aplica escala de grises */
}



/* ======= FOOTER ======= */



.footer .footer-section__list:before,
.footer .footer-section__list:after {
  display: table;
  content: "";
}

.footer .footer-section__list:after {
  clear: both;
}

.lt-ie8 .footer .footer-section__list {
  zoom: 1;
}

.footer .footer-section__list li {
  float: left;
  width: 50%;
  margin-bottom: 10px;
  padding-left: 18px;
}

.footer .footer-section__list li:before {
  left: -7px;
  font-size: 18px;
}

.footer .footer-section__list li a {
  color: #ccc;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.footer .footer-social-net {
  margin-bottom: 23px;
}

.footer .footer-social-net:before,
.footer .footer-social-net:after {
  display: table;
  content: "";
}

.footer .footer-social-net:after {
  clear: both;
}

.lt-ie8 .footer .footer-social-net {
  zoom: 1;
}

.footer .footer-social-net__icon {
  float: left;
  padding-top: 6px;
  padding-left: 5px;
  font-size: 16px;
}

.footer .footer-social-net__inner {
  padding-left: 30px;
}

.footer .footer-social-net__text {
  color: #ccc;
}

.footer .footer-social-net__time {
  display: block;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 700;
  color: #777;
  letter-spacing: 0.035em;
  text-transform: uppercase;
}

.footer .footer-main-section {
  margin-top: 57px;
}

.footer .footer-main-section_left {
  padding-right: 15px;
  text-align: right;
}

.footer .footer-main-section_right {
  padding-left: 15px;
}

.footer .footer-form {
  position: relative;
  max-width: 300px;
  margin-top: 14px;
  background-color: #191919;
}

.footer .footer-form__info {
  color: #ccc;
}

.footer .footer-form__input,
.footer .footer-form__btn {
  border: none;
  background-color: transparent;
}

.footer .footer-form__input {
  width: 100%;
  padding: 10px 50px 10px 20px;
}

.footer .footer-form__btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  line-height: 1;
}

.footer .footer-banner {
  position: relative;
  margin-top: -20px;
  margin-bottom: 20px;
}

.footer .footer-banner__decor {
  position: absolute;
  top: -24px;
  left: -24px;
  width: 40px;
  height: 40px;
  border: 6px solid #3b3b3b;
  z-index: 1;
}

.footer .footer-banner__inner {
  position: relative;
  padding: 40px 55px 50px;
  color: #fff;
  background-image: url("../../assets/media/components/footer/6.jpg");
  background-size: cover;
  z-index: 10;
}

.footer .footer-banner__wrap {
  float: left;
  padding-right: 70px;
}

.footer .footer-banner__title {
  font-size: 20px;
  color: #fff;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.footer .footer-banner__text {
  font-size: 15px;
  font-style: italic;
  color: #ccc;
  letter-spacing: 0.015em;
}

.footer .footer-banner__btn {
  float: right;
  margin-top: 17px;
  padding: 15px 30px;
}

.footer_mod-a .footer-section__list li {
  float: none;
  width: 100%;
}

.footer_mod-a .footer-section_top-sm {
  margin-top: 52px;
}

.footer_mod-a .social-net {
  margin-top: 30px;
  text-align: left;
}

.footer_mod-a .social-net__link {
  width: 22px;
  border: none;
}

.footer_mod-a .footer-form {
  max-width: 100%;
}

.footer_mod-a .footer__logo {
  display: block;
  margin-top: 60px;
  margin-bottom: 26px;
}

.footer_mod-a .footer__contact_lg {
  margin-top: 31px;
}
a {
  text-decoration: none;
}
</style>