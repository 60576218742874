import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import interceptor from './utils/interceptor';
import { createPinia } from 'pinia'
import { loadFonts } from './plugins/webfontloader'

loadFonts()


const pinia = createPinia()
createApp(App)
  .use(i18n)
  .use(pinia)
  .use(router)
  .use(vuetify)
  .mount('#app')

  interceptor()
