<template>
  <v-app class="app-main">
    <v-main>
      <router-view />
      <bcg-footer />
    </v-main>
    <v-snackbar v-model="snackbar" :timeout="timeout" multi-line>
      <v-row class="mt-10" align="center" justify="center" no-gutters>
        <v-col cols="12" md="10">
          <p class="my-5 text-body-1">Este sitio web utiliza cookies para mejorar su experiencia y proporcionar
            funcionalidades adicionales. Las
            cookies son pequeños archivos de texto que se almacenan en su dispositivo cuando visita ciertos sitios
            web.
            Ayudan a mejorar el rendimiento y la seguridad del sitio, así como a recordar sus preferencias y
            acciones
            previas.</p>

          <p class="my-5 text-body-1">Al continuar navegando en este sitio, acepta el uso de cookies. Puede configurar
            su navegador para que
            rechace las cookies o le notifique cuando se envíen cookies. Sin embargo, si elige rechazar las cookies,
            es
            posible que algunas partes del sitio no funcionen correctamente.</p>

          <p class="my-5 text-body-1">Para obtener más información sobre cómo usamos las cookies y cómo puede
            gestionarlas, lea nuestra <a href="cookies">Política de Cookies</a>.</p>

          <p class="my-5 text-body-1">Gracias por su comprensión y cooperación.</p>
        </v-col>
      </v-row>

      <template v-slot:actions>
        <v-btn color="red" variant="text" @click="setCookiesPreferences(true)">
          Aceptar
        </v-btn>
        <v-btn color="red" variant="text" @click="setCookiesPreferences(false)">
          Rechazar
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>
<script>
import { BcgFooter } from '@/components';
const COOKIE_SITE = 'bcGorup-preferences'
const currentDate = new Date();
export default {
  components: { BcgFooter },
  data: () => ({
    snackbar: false,
    timeout: 50000,
  }),
  mounted() {
    const user_pref = JSON.parse(localStorage.getItem(COOKIE_SITE));
    if (!user_pref) {
      this.snackbar = true
    }
    else {
      this.snackbar = false
    }
  },
  methods: {
    deleteAllCookies() {
      // Obtener todas las cookies como un array
      const cookies = document.cookie.split(";");
      // Iterar a través de todas las cookies y eliminarlas
      cookies.forEach(cookie => {
        // Obtener el nombre de la cookie
        const cookieName = cookie.split("=")[0].trim();
        // Eliminar la cookie estableciendo su fecha de expiración en el pasado
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      });
    },
    setCookiesPreferences(state) {
      localStorage.setItem(COOKIE_SITE, JSON.stringify({
        cookie: state,
        date: this.formatDate(currentDate)
      }));
      if (!state) {
        this.deleteAllCookies()
      }
      this.snackbar = false
    },
    formatDate(date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');

      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }
  }
}
</script>

<style lang="scss">
@import url('./assets/css/parallax.css');
@import url('./assets/css/master.css');


html {
  scroll-behavior: smooth;
  font-family: "Montserrat", sans-serif;
}

.app-main {
  top: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  background-color: #0f0f0f;
  color: #2c3e50;

}
</style>
