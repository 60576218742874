<template>

  <!-- BARAA DE NAVEGACION MODO DESKTOP -->
  <div v-if="!$vuetify.display.mobile" :class="{ 'l-theme animated-css sticky': !isAtTop }" class="bcg-bar">
    <bcg-bar :scroll="showScrollButton" />
  </div>

  <!-- BARAA DE NAVEGACION MODO MOBILE -->
  <v-app-bar v-else style="top: 0 !important; margin-top: 0px !important" color="primary" :elevation="0">
    <template v-slot:prepend>
      <v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </template>

    <v-app-bar-title>BC Group</v-app-bar-title>
  </v-app-bar>


  <!-- PANEL DE NAVEGACION MODO MOBILE -->
  <v-navigation-drawer v-show="drawer" v-model="drawer" width="350" app fixed temporary>
    <v-card flat>
      <v-img contain dark height="250px" :src="logo" container>
      </v-img>
      <v-divider></v-divider>
      <v-list class="list-nav-text text-left" dense nav two-line>
        <v-list-item-group v-model="group" active-class="primary-text text--accent-4">
          <v-list-item link :href="l.link" v-for="(l, i) in links" :key="i">
            <v-list-item-icon>
              <v-icon color="primary">
                {{ l.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ l.name }}</v-list-item-title>
              <v-list-item-subtitle> {{ l.subtitle }} </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-navigation-drawer>


  <!-- CARRUSEL DE IMAGENES -->
  <bc-carousel></bc-carousel>


  <!-- QUIENES SOMOS -->
  <v-parallax id="about" src="https://www.servrsc.com/resources/bc_group/parallax/1.jpg" scale="0.2">
    <v-row class="mt-10 mb-7 section-default wow" align="center" justify="center" no-gutters>
      <v-col cols="12" md="7">
        <section class="b-type-b">

          <!-- VIDEO MODO DESKTOP -->
          <div v-if="!$vuetify.display.mobile" class="b-type-b__media">
            <div class="b-type-b__media-title text-primary">{{ $t('video_title') }} <v-btn
                style="transform: rotate(90deg);" icon="mdi mdi-play" @click="playVideo"></v-btn></div>
            <div class="b-video player fixed-controls play-button">
              <img :src="poster_1" alt="Poster" class="video-poster" />
            </div>
          </div>
          <div class="b-type-b__inner">
            <h2 class="text-h4 font-weight-bold pl-2">{{ $t('about') }}</h2>
            <img class="about-pleca pl-2" :src="line">
            <div class="b-type-b__subtitle ui-subtitle-block font-weight-bold px-5">
              {{ $t('about_subtitle') }}
            </div>
            <div class="b-type-b__text text-justify px-5">
              <p class="mt-5 text-body-1">
                {{ $t('about_txt_p1') }}
              </p>
              <p class="mt-5 text-body-1">
                {{ $t('about_txt_p2') }}
              </p>
            </div>

          </div>

          <!-- VIDEO MODO MOBILE -->
          <div v-if="$vuetify.display.mobile" class="" @click="playVideo">
            <div class="text-center">
              <img :src="poster_2" alt="Poster" class="video-poster" />
            </div>
          </div>
        </section>
      </v-col>
    </v-row>

    <!-- VALORES -->
    <v-row id="values" class="mt-10" lign="center" justify="center" no-gutters>
      <v-col cols="12" md=12 :class="$vuetify.display.mobile ? 'pl-10 pr105 text-justify' : 'pl-5'">
        <h2 class="text-h4 font-weight-bold">{{ $t('values') }}</h2>
        <img class="about-pleca" :src="line">
      </v-col>
      <BcCard :lng="$t('values')" :showDescription="false" />
    </v-row>
  </v-parallax>

  <!-- BROCHURE Y DEMAS PDFS -->
  <v-parallax id="invest" src="https://www.servrsc.com/resources/bc_group/parallax/6.jpg" scale="0.2">
    <v-row class="mt-10">
      <v-col cols="12" md="12" :class="$vuetify.display.mobile ? 'pl-10 pr105 text-justify' : 'pl-5'">
        <h2 class="text-h4 font-weight-bold pr-5">{{ $t('invest_title') }}</h2>
        <img class="about-pleca" :src="line">
      </v-col>
    </v-row>
    <v-row class="brochure-section" align="center" justify="center">
      <v-col cols="12" md="9">
        <p class="my-5 text-justify text-body-1 px-5">
          {{ $t('invest_txt1') }}
        </p>
        <p class="my-5 text-justify text-body-1 px-5">
          {{ $t('invest_txt2') }}
        </p>
      </v-col>
      <v-col cols="12" md="4" class="text-center px-5">
        <p class="my-5 text-body-1">
          {{ $t('invest_subtitle') }}
        </p>
      </v-col>
      <v-col cols="12" md="8" class="text-center">
        <bc-brochure :lng="$t('invest_title')"></bc-brochure>
      </v-col>
    </v-row>
  </v-parallax>

  <!-- NUESTRAS EMPRESAS-->
  <v-parallax id="companies" src="https://www.servrsc.com/resources/bc_group/parallax/1.jpg">
    <v-row class="mt-10">
      <v-col cols="12" md=12 :class="$vuetify.display.mobile ? 'pl-10 pr105 text-justify' : 'pl-5'">
        <h2 class="text-h4 font-weight-bold">{{ $t('companies') }} </h2>
        <img class="about-pleca" :src="line">
      </v-col>
    </v-row>
    <v-row no-gutters lign="center" justify="center">
      <v-col cols="12" md="3" v-for="(c, i) in companies" :key="i">
        <v-card @click="showCompany(c)" color="transparent" flat>
          <v-img :src="c.src" height="200px">
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-parallax>

  <!-- CONTACTO -->
  <v-row style="width: 100%; left: 0; right: 0;" class="section-area section-table clearfix">
    <v-col cols="12" md="12">
      <section id="contact" class="b-form-request section-table__inner">
        <div class="bg-section-form-contact b-form-request__parallax section-bg section-bg_primary jarallax"></div>
        <div class="section__inner">
          <div class="b-form-request__inner">
            <h2 class="b-form-request__title"><span class="b-form-request__title-emphasis">{{ $t('contact_title') }}En
              </span></h2>
            <div class="b-form-request__text text-left">
              <p>
                {{ $t('contact_txt1') }}
              </p>
              <p>
                {{ $t('contact_txt2') }}
              </p>
            </div>
          </div>
          <!-- :class="$vuetify.display.mobile ? 'b-form-request__form_mob' : 'b-form-request__form' " -->
          <v-form ref="form_contact" class="b-form-request__form"
            :class="$vuetify.display.mobile ? 'b-form-request__mobile' : ''">
            <v-row>
              <v-col cols="12" md="12">
                <v-select density="compact" :label="$t('form_subject')" v-model="contact.subject" variant="outlined"
                  hide-details :items="subjectList" :rules="[rules.required]"></v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field density="compact" :label="$t('form_name')" v-model="contact.name" variant="outlined"
                  hide-details="" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field density="compact" :label="$t('form_phone')" v-model="contact.phone" variant="outlined"
                  hide-details="" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field density="compact" :label="$t('form_email')" v-model="contact.email" variant="outlined"
                  hide-details="" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea density="compact" :label="$t('form_message')" v-model="contact.message" variant="outlined"
                  row-height="20" rows="2" hide-details="" :rules="[rules.required]"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-btn variant="outlined" width="150" @click="sendmessage">
                  {{ $t('btn_send') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </section>
      <section class="bg-section-form-contact-2 b-type-d section-bg jarallax section-table__inner">
        <div class="section__inner"><img :src="logo" alt="Logo" class="img-responsive" />
          <!-- <br>
          <h2 class="b-type-d__title ui-title-inner-1">Si desea obtener más información sobre nuestos servicios,
            ¡contáctenos!</h2> -->
        </div>
      </section>
    </v-col>
  </v-row>

  <v-btn v-show="showScrollButton" @click="scrollToTop" class="scroll-to-top" color="primary" fab>
    <v-icon>mdi-arrow-up</v-icon>
  </v-btn>

  <!-- MAPA-->
  <v-row>
    <v-col cols="12" md="12" class="pa-5">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3794.7924896861923!2d-92.9501069182075!3d17.98838884141275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85edd775980c70d1%3A0xb2b366db032b5a75!2sBC%20GROUP%20MX!5e0!3m2!1ses-419!2smx!4v1740691271995!5m2!1ses-419!2smx"
        width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
    </v-col>
  </v-row>


</template>

<script>
import { useContactStore } from '@/stores/contact'
import { useCommonStore } from '@/stores/commons'
import 'animate.css';
import Swal from 'sweetalert2'
import { BcCarousel, BcgBar, BcCard, BcBrochure, CardCompany } from '@/components';
import logo from '@/assets/logo.png'


import poster_1 from '@/assets/media/components/b-video/poster-1.jpg'
import poster_2 from '@/assets/media/components/b-video/poster-2.jpg'


export default {
  name: 'HomeView',
  components: { BcCarousel, BcgBar, BcCard, BcBrochure, CardCompany },
  computed: {
    lng() {
      const cStore = useCommonStore()
      let u = cStore.lng;
      return u;
    },
  },
  watch: {
    lng: {
      handler() {
        this.setCompanyList();
      },
      immediate: true
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  data: () => ({
    drawer: false,
    showScrollButton: false,
    translationKey: '',
    logo: logo,
    poster_1: poster_1,
    poster_2: poster_2,
    videoSrc: 'https://www.servrsc.com/resources/bc_group/videos/bc_group_page.mp4',
    isAtTop: true,
    dialogPDF: false,
    pdfUrl: null,
    dataApplicationDF: 'data:application/pdf;base64',
    bcg: require('@/assets/images/bc.png'),
    line: require('@/assets/images/rect3349.png'),
    contact: {},
    links: [],
    subjectList: [],
    companies: [],
    ourValues: [],
    rules: {
      required: (v) => !!v || "Este campo es requerido",
      email: (v) => !!(v || "").match(/@/) || "Ingrese un email válido",
    },
  }),

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    onOpen(state) {
      this.drawer = state
    },
    setLinks() {
      this.links = [
        { name: this.$t('we'), title: this.$t('we'), subtitle: this.$t('we'), icon: 'mdi-information-outline', link: '#about' },
        { name: this.$t('values'), title: this.$t('values'), subtitle: this.$t('values'), icon: 'mdi mdi-medal-outline', link: '#values' },
        { name: this.$t('companies'), title: this.$t('companies'), subtitle: this.$t('companies'), icon: 'mdi mdi-lightbulb-group-outline', link: '#companies' },
        { name: this.$t('invest'), title: this.$t('invest'), subtitle: this.$t('invest'), icon: 'mdi mdi-finance', link: '#invest' },
        { name: this.$t('contact'), title: this.$t('contact'), subtitle: this.$t('contact'), icon: 'mdi mdi-face-agent', link: '#contact' },
      ]
    },
    handleScroll() {
      this.isAtTop = window.scrollY === 0;
      this.showScrollButton = window.scrollY > 100;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    async sendmessage() {
      const store = useContactStore();

      const { valid } = await this.$refs.form_contact.validate()
      if (valid) {
        let data = {
          item: this.contact,
          entity: 'contact'
        }
        store.sender(data).then(
          (rs) => {
            if (rs == 201) {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Mensaje enviado",
                text: "El mensaje fue enviado, en breve un asesor se contactará con UD.",
                showConfirmButton: false,
                timer: 3500,
              }).then(() => {
                this.$refs.form_contact.reset()
                this.overlay = false
              });
            }
          },
          () => { }
        );
      }
      else {
        Swal.fire({
          position: "top-end",
          icon: "warning",
          title: "Campos obligatorios",
          text: "Los campos marcados son requeridos.",
          showConfirmButton: false,
          timer: 3500,
        }).then(() => {
          this.$refs.form_contact.reset()
          this.overlay = false
        });
      }
    },
    playVideo() {
      Swal.fire({

        html: ` <video id="video" controls poster="${this.poster_1}" style="width: 100%;"> <source src="${this.videoSrc}" type="video/mp4"/> Your browser does not support the video tag. </video> `,
        showClass: {
          popup: `
                  animate__animated
                  animate__fadeInUp
                  animate__faster
                  `,
        },
        hideClass: {
          popup: `
                  animate__animated
                  animate__fadeOutDown
                  animate__faster
          `,
        },
        customClass: {
          container: "mySwalVd",
          popup: "myPopup"
        },
        showCancelButton: false,
        showConfirmButton: false,
        didOpen: () => {
          const video = Swal.getPopup().querySelector('#video');
          video.addEventListener('canplay', () => {
            video.play();
          });
          //  const video = Swal.getPopup().querySelector('#video'); video.play(); 
        },
        willClose: () => { const video = Swal.getPopup().querySelector('#video'); video.pause(); }
      })
    },
    setSubjectList() {
      this.subjectList = [
        this.$t('subjet1'),
        this.$t('subjet2'),
        this.$t('subjet3'),
        this.$t('subjet4')
      ]
    },
    setCompanyList() {
      this.setLinks()
      this.setSubjectList();
      if (this.$t('mylng') == 'es') {
        this.companies = [
          {
            id: 1,
            name: 'PRIME LOGIS',
            description: 'Empresa dedicada a la comercialización de materiales para diferentes tipos de obra, estructuras y construcción.',
            services: [
              { description: 'Comercialización de material primario de construcción.' },
              { description: 'Comercialización de piezas y equipo especial para la construcción.' },
              { description: 'Comercialización de equipo ferretero, de sistemas hidráulicos, eléctricos y tuberías.' }
            ],
            src: 'https://www.servrsc.com/resources/bc_group/companies/1.png'
          },
          {
            id: 2,
            name: 'OLMECA GREEN CONSTRUCCIONES',
            description: 'Proveedor de productos y servicios para empresas de la industria oil & gas y empresas del sector privado en el ramo de la construcción.',
            services: [
              { description: 'Suministro y comercialización de material para la construcción.' },
              { description: 'Servicios de ingeniería y obra civil.' },
              { description: 'Nuevas tecnologías en la construcción.' },
              { description: 'Administración y supervisión de la construcción.' },
              { description: 'Construcción de obras y servicios de arquitectura.' },
              { description: 'Construcción de carreteras, autopistas, terracerías, puentes, pasos a desnivel y aeropistas.' },
              { description: 'Construcción de obras de urbanización.' },
              { description: 'Construcción de vivienda unifamiliar.' }
            ],
            src: 'https://www.servrsc.com/resources/bc_group/companies/2.png'
          },
          {
            id: 3,
            name: 'EFFICIENT SOLUTIONS',
            description: 'Ofrece soluciones administrativas para las empresas a través de nuestro personal capacitado.',
            services: [
              { description: 'Sistema de facturación.' },
              { description: 'Sistema de compras.' },
              { description: 'Cálculos para comprobaciones fiscales más eficientes.' },
              { description: 'Contabilidad.' },
              { description: 'Registros y cálculos de Recursos Humanos.' },
              { description: 'Detalle de compraventa.' },
              { description: 'Sistema de pedidos y proveeduría.' }
            ],
            src: 'https://www.servrsc.com/resources/bc_group/companies/3.png'
          },
          {
            id: 4,
            name: 'AET',
            description: 'Empresa dedicada a la prestación de servicios por medio de personal especializado, así como a la capacitación y actualización del mismo.',
            services: [
              { description: 'Comprometidos con dar servicios de primera calidad en los ramos de proveeduría de personal, AET entrena, capacita y actualiza a personal de sectores especializados para proveer dichos servicios.' }
            ],
            src: 'https://www.servrsc.com/resources/bc_group/companies/4.png'
          },
          {
            id: 5,
            name: 'COMERTAB',
            description: 'Empresa dedicada a la comercialización y arrendamiento de equipo industrial y maquinaria pesada.',
            services: [
              { description: 'Compra y venta de maquinaria pesada.' },
              { description: 'Compra y venta de equipo especializado industrial.' },
              { description: 'Arrendamiento de equipo y maquinaria pesada.' },
              { description: 'Alquiler de equipo para el comercio y los servicios.' }
            ],
            src: 'https://www.servrsc.com/resources/bc_group/companies/5.png'
          },
          {
            id: 6,
            name: 'YEMAYA',
            description: 'Desarrollo de tecnologías de la industria informática.',
            services: [
              { description: 'Desarrollos de software necesarios para tu empresa.' }
            ],
            src: 'https://www.servrsc.com/resources/bc_group/companies/6.png'
          },
          {
            id: 7,
            name: 'OBGE',
            description: 'Empresa dedicada a los servicios de seguridad privada intramuros.',
            services: [
              { description: 'Servicios de seguridad privada empresarial o residencial.' },
              { description: 'Servicios de protección y custodia mediante el monitoreo de sistemas de seguridad o sin la misma.' }
            ],
            src: 'https://www.servrsc.com/resources/bc_group/companies/7.png'
          },
          {
            id: 8,
            name: 'LA VERACRUZANA',
            description: 'Empresa dedicada al arrendamiento y comercialización de transportes de diversos tipos.',
            services: [
              { description: 'Arrendamiento de vehículos de transportación de contenido especializado.' },
              { description: 'Compra y venta de vehículos especializados.' },
              { description: 'Comercialización de autopartes y adaptaciones.' },
              { description: 'Arrendamiento de vehículos a gran escala.' },
              { description: 'Arrendamiento de vehículos de todo tipo.' }
            ],
            src: 'https://www.servrsc.com/resources/bc_group/companies/8.png'
          },
          {
            id: 9,
            name: 'OCEAN',
            description: 'Empresa dedicada a obras y servicios costa fuera como son:',
            services: [
              { description: 'Servicios de mantenimiento de instalaciones hidráulicas, eléctricas y mecánicas.' },
              { description: 'Venta y comercialización de combustible y gas a compañías industriales.' },
              { description: 'Obra civil y arquitectónica.' }
            ],
            src: 'https://www.servrsc.com/resources/bc_group/companies/9.png'
          },
          {
            id: 10,
            name: 'OLOKUN DEL SURESTE',
            description: 'Empresa dedicada al mantenimiento e instalación de ductos y otros trabajos de acabados, mantenimientos e instalaciones en edificaciones.',
            services: [],
            src: 'https://www.servrsc.com/resources/bc_group/companies/10.png'
          }
        ]
      }
      else {
        this.companies = [
          {
            "id": 1,
            "name": "PRIME LOGIS",
            "description": "A company dedicated to the commercialization of materials for different types of construction, structures, and building.",
            "services": [
              { "description": "Commercialization of primary construction materials." },
              { "description": "Commercialization of parts and special equipment for construction." },
              { "description": "Commercialization of hardware equipment, hydraulic systems, electrical systems, and pipes." }
            ],
            "src": "https://www.servrsc.com/resources/bc_group/companies/1.png"
          },
          {
            "id": 2,
            "name": "OLMECA GREEN CONSTRUCCIONES",
            "description": "A supplier of products and services for companies in the oil & gas industry and private sector construction companies.",
            "services": [
              { "description": "Supply and commercialization of construction materials." },
              { "description": "Engineering and civil works services." },
              { "description": "New technologies in construction." },
              { "description": "Construction management and supervision." },
              { "description": "Building works and architecture services." },
              { "description": "Construction of roads, highways, earthworks, bridges, underpasses, and airstrips." },
              { "description": "Construction of urbanization works." },
              { "description": "Construction of single-family housing." }
            ],
            "src": "https://www.servrsc.com/resources/bc_group/companies/2.png"
          },
          {
            "id": 3,
            "name": "EFFICIENT SOLUTIONS",
            "description": "Offers administrative solutions for companies through our trained staff.",
            "services": [
              { "description": "Billing system." },
              { "description": "Purchasing system." },
              { "description": "Calculations for more efficient tax verifications." },
              { "description": "Accounting." },
              { "description": "Human resources records and calculations." },
              { "description": "Buy-sell details." },
              { "description": "Order and supply system." }
            ],
            "src": "https://www.servrsc.com/resources/bc_group/companies/3.png"
          },
          {
            "id": 4,
            "name": "AET",
            "description": "A company dedicated to providing services through specialized personnel, as well as training and updating them.",
            "services": [
              { "description": "Committed to providing first-quality services in the field of personnel supply, AET trains, educates, and updates personnel in specialized sectors to provide these services." }
            ],
            "src": "https://www.servrsc.com/resources/bc_group/companies/4.png"
          },
          {
            "id": 5,
            "name": "COMERTAB",
            "description": "A company dedicated to the commercialization and leasing of industrial equipment and heavy machinery.",
            "services": [
              { "description": "Buying and selling of heavy machinery." },
              { "description": "Buying and selling of specialized industrial equipment." },
              { "description": "Leasing of heavy equipment and machinery." },
              { "description": "Rental of equipment for commerce and services." }
            ],
            "src": "https://www.servrsc.com/resources/bc_group/companies/5.png"
          },
          {
            "id": 6,
            "name": "YEMAYA",
            "description": "Development of information technology industry technologies.",
            "services": [
              { "description": "Software development necessary for your business." }
            ],
            "src": "https://www.servrsc.com/resources/bc_group/companies/6.png"
          },
          {
            "id": 7,
            "name": "OBGE",
            "description": "A company dedicated to private security services within premises.",
            "services": [
              { "description": "Private security services for businesses or residential areas." },
              { "description": "Protection and custody services through monitoring of security systems or without them." }
            ],
            "src": "https://www.servrsc.com/resources/bc_group/companies/7.png"
          },
          {
            "id": 8,
            "name": "LA VERACRUZANA",
            "description": "A company dedicated to the leasing and commercialization of various types of transportation.",
            "services": [
              { "description": "Leasing of vehicles for specialized transportation." },
              { "description": "Buying and selling of specialized vehicles." },
              { "description": "Commercialization of auto parts and adaptations." },
              { "description": "Leasing of vehicles on a large scale." },
              { "description": "Leasing of all types of vehicles." }
            ],
            "src": "https://www.servrsc.com/resources/bc_group/companies/8.png"
          },
          {
            "id": 9,
            "name": "OCEAN",
            "description": "A company dedicated to offshore works and services, such as:",
            "services": [
              { "description": "Maintenance services for hydraulic, electrical, and mechanical installations." },
              { "description": "Sale and commercialization of fuel and gas to industrial companies." },
              { "description": "Civil and architectural works." }
            ],
            "src": "https://www.servrsc.com/resources/bc_group/companies/9.png"
          },
          {
            "id": 10,
            "name": "OLOKUN DEL SURESTE",
            "description": "A company dedicated to the maintenance and installation of ducts and other finishing, maintenance, and installation work in buildings.",
            "services": [],
            "src": "https://www.servrsc.com/resources/bc_group/companies/10.png"
          }
        ]
      }

    },
    generateHtmlList(services) {
      if (!Array.isArray(services) || services.length === 0) {
        return '<ul></ul>';
      }

      const listItems = services.map(service => `<li>${service.description}</li>`).join('');
      return `<ul class="text-left">${listItems}</ul>`;
    },
    showCompany(c) {
      let listHtml = this.generateHtmlList(c.services)
      let card = ` <div class="">
            <div>
                <p class="text-justify text-body-1 mb-7">
                    ${c.description}
                  </p>   
            </div>
            <div class="px-5">
              ${listHtml}
              </div>
        </div>
    </v-card> `

      Swal.fire({
        imageUrl: `${c.src}`,
        imageWidth: 300,
        imageAlt: "logo empresa",
        padding: "3em",
        color: "#ffffff",
        background: "rgba(2, 56, 89, 0.5) ",
        width: "600px",
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
        showClass: {
          popup: `
        animate__animated
        animate__fadeInUp
        animate__faster
      `
        },
        hideClass: {
          popup: `
        animate__animated
        animate__fadeOutDown
        animate__faster
      `
        },
        customClass: {
          container: "my_swal-cia",
          popup: "my-popup-cia",
          image: "my-image-cia"
        },
        title: `<strong>${c.name}</strong>`,
        html: `${card}`,
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
    }
  }

}
</script>
<style scoped>
.brochure-section {
  color: #fff;
  background-color: #023859e8;
  /* 0.5 es el valor de transparencia */
}

.b-video {
  position: relative;
  display: inline-block;
}

.myPopup {
  background-color: #000;
}

.video-container {
  position: relative;
}

.video-slogan {
  position: absolute;
  /* font-size: 20px; */
  bottom: 30px;
  color: #fff;
  text-align: center;
  width: 100%;
}

.menu-video {
  z-index: 9999;
  position: absolute;
  bottom: 200px;
}

.myPopup .swal2-html-container {
  padding: 1px !important;
}

.my_swal-cia {
  background-color: #000;
}

.my-popup-cia {
  /*  background-color: rgba(0, 0, 0, 0.5) !important; Fondo negro con 50% de transparencia */
  background-color: rgba(2, 56, 89, 0.5) !important;
  color: #fff !important;
  /* Texto blanco */
  width: 600px !important;
  /* Ancho personalizado */
  max-width: 90%;
  /* Asegura que no sea más grande que la pantalla */
  border-radius: 10px;
  /* Bordes redondeados */
}

.my-popup-cia-txt {
  color: #fff !important;
  /* Texto blanco */
}

.bg-section-form-contact {
  background-image: url("../assets/media/components/b-form-request/1.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.bg-section-form-contact-2 {
  background-image: url("../assets/media/components/b-form-request/2.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.bg-section-form-pdfs {
  background-image: url("https://www.servrsc.com/resources/bc_group/parallax/6.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}


.bcg-bar {
  /* background-color: rgba(0, 0, 0, 0.3) !important; */
  background-color: #023859;
  /* #021520e8;*/
}

a {
  text-decoration: none;
}

.fixed-bar {
  position: fixed;
  top: 32px;
  /* Ajusta este valor según la altura de tu v-system-bar */
  width: 100%;
  z-index: 1000;
  /* Asegura que esté por encima del contenido */
}

.content-view {
  margin-top: 80px;
  /* Ajusta este valor según la altura combinada de v-system-bar y ogbe-bar */
  padding: 16px;
  /* Opcional: añade un poco de padding para el contenido */
}

.scroll-to-top {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 999999;
}

.b-form-request__mobile {
  padding: 60px 15px !important;

}

/* .my-popup-cia .my-image-cia {
    filter: invert(1);
} */
.cia-list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 80%;
  max-width: 500px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.cia-list li {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #eee;
  transition: background-color 0.3s ease;
}

.cia-list li:last-child {
  border-bottom: none;
}

.cia-list li:hover {
  background-color: #f0f8ff;
}

.cia-list .icon {
  width: 30px;
  height: 30px;
  background-color: #023859;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  color: #fff;
  font-weight: bold;
}

.cia-list .text {
  font-size: 18px;
  color: #333;
}

.cia-list .text strong {
  color: #023859;
}
</style>
