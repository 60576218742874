// src/i18n.js
import { createI18n } from 'vue-i18n'

// Definición de mensajes de traducción
const messages = {
    en: {
        mylng: 'en',
        //MENU NAVBAR
        we: 'Who Are We?',
        values: 'Our Values',
        companies: 'Our Companies',
        invest: 'Do you Want to Invest with Us?',
        contact: 'Contact',
        //CAROUSEL
        carousel_label: 'A corporation from the southeastern Mexican region that is steadily progressing and interested in growing your wealth.',
        //HOME
        about: 'About Us',
        about_subtitle: "A corporation from southeastern Mexico that progresses firmly and is interested in growing your assets.",
        about_txt_p1: 'We are a group of companies that provide services for the energy industry, such as civil and infrastructure work onshore and offshore, production engineering, environmental engineering, non-destructive testing, machining of special tools, industrial and residential maintenance, as well as administrative, legal, and accounting management services, with the aim of being part of an EFFECTIVE, MODERN, TECHNOLOGICAL, and above all HONEST solution.',
        about_txt_p2: 'Our solution transcends fulfillment, responsibility, innovation, and sustainability in every task or project, both with our commercial alliances and with our investors.',
        video_title: 'Our Video',
        //VALORES
        title_1: 'Honesty',
        text_val1: 'The quality of acting with sincerity and transparency in all our projects and relationships.',
        title_2: 'Responsibility and Commitment',
        text_val2: 'Always taking responsibility for every situation defines us, always resolving to fulfill every commitment.',
        title_3: 'Teamwork',
        text_val3: 'Our human capital is our most reliable source of growth; having the most prepared and committed staff makes us stronger.',
        //SECCION INVERTIR EN NOSOTROS PDF'S
        invest_title: 'Do you want to invest with us?',
        invest_subtitle: 'Get to know the investment options we have for you:',
        invest_txt1: 'Our company has grown steadily since 2015 thanks to a group of investors with whom this great consortium was started. Over the years, growth has been even greater due to the trust of entrepreneurs and people looking to invest in our company, which in turn now belongs to everyone.',
        invest_txt2: 'That is why at BC Group we have become professional in investment analysis, having a team of experts to ensure that every peso invested in us grows more efficiently.',
        btn_brochure: 'Brochure',
        btn_invest: 'Investor Program',
        btn_nofinance: 'Non-financial Solutions',
        pdf_1:'BROCHURE_BC_GROUP_EN',
        pdf_2:'PROGRAMA_INVERSOR_EN',
        pdf_3:'SOLUCIONES_NO_FINANCIERAS_EN',
        //CONTACTO
        contact_title: 'At BC Group',
        contact_txt1: 'We value our clients and strive to provide close and personalized attention.',
        contact_txt2: 'We are available to address your concerns and provide fast and effective solutions.',
        subjet1: 'Contact',
        subjet2: 'Investments',
        subjet3: 'Non-financial Solutions',
        subjet4: 'Suppliers or commercial alliances',
        form_subject: 'Subject',
        form_name: 'Full Name',
        form_phone: 'Phone',
        form_email: 'Email',
        form_message: 'Message',
        btn_send: "Send",
        error_title: "Required Fields",
        error_msg: "The marked fields are required.",
        msg_title: "Message Sent",
        msg_success: "The message was sent, shortly an advisor will contact you."
    },
    es: {
        mylng: 'es',
        //MENU NAVBAR
        we: '¿Quiénes Somos?',
        values: 'Nuestros Valores',
        companies: 'Nuestras Empresas',
        invest: '¿Quieres Invertir con Nosotros?',
        contact: 'Contacto',
        //CAROUSEL
        carousel_label: "Un corporativo del sureste mexicano que progresa firme e interesado en hacer crecer tu patrimonio.",
        //HOME
        about: 'Acerca de Nosotros',
        about_subtitle: "Un corporativo del sureste mexicano que progresa firme e interesado en hacer crecer tu patrimonio.",
        about_txt_p1: 'Somos un grupo de empresas que provee y da servicios para la industria energética, como obra civil y de infraestructura terrestres y costa fuera, ingeniería de producción, ingeniería ambiental, ensayos no destructivos, maquinado de herramientas especiales, mantenimiento industrial y residencial, además de servicios de gestoría administrativa, legal y contable con la finalidad de ser parte de una solución EFICAZ, MODERNA, TECNOLOGICA y sobre todo HONESTA.',
        about_txt_p2: 'Nuestra solución trasciende en el cumplimiento, la responsabilidad, innovación y sustentabilidad de cada trabajo o proyecto tanto con nuestras alianzas comerciales como con nuestros inversionistas.',
        video_title: 'Nuestro video',
        //VALORES
        title_1: 'Honestidad',
        text_val1: 'La cualidad de actuar con sinceridad y transparencia en todos nuestros proyectos y relaciones.',
        title_2: 'Responsabilidad y Cumplimiento',
        text_val2: 'Asumir siempre la responsabilidad ante toda situación nos define, resolviendo siempre para cumplir todo compromiso.',
        title_3: 'Trabajo en Equipo',
        text_val3: 'Nuestro capital humano es nuestra fuente mas fiable de crecimiento, contar con el personal mas preparado y comprometido nos hace más fuertes.',
        //SECCION INVERTIR EN NOSOTROS PDF'S
        invest_title: '¿Deseas invertir con nosotros?',
        invest_subtitle: 'Conoce las opciones de inversión que tenemos para tí:',
        invest_txt1: 'Nuestra empresa ha crecido desde 2015 a pasos firmes gracias a un grupo de inversores con el que se inició este gran consorcio. Desde hace varios años el crecimiento ha sido aun mayor debido a la confianza de los empresarios y personas que buscan invertir en nuestra empresa que a su vez ya es de todos.',
        invest_txt2: 'Es por eso que en BC Group nos hemos profesionalizado en el análisis de inversión contando con un equipo de expertos para que realmente cada peso invertido en nosotros crezca de manera más eficiente.',
        btn_brochure: 'Brochure',
        btn_invest: 'Programa Inversor',
        btn_nofinance: 'Soluciones no financieras',
        pdf_1:'BROCHURE_BC_GROUP',
        pdf_2:'PROGRAMA_INVERSOR',
        pdf_3:'SOLUCIONES_NO_FINANCIERAS',

        //CONTACTO
        contact_title: 'En BC Group ',
        contact_txt1: 'Valoramos a nuestros clientes y nos esforzamos por brindar una atención cercana y personalizada.',
        contact_txt2: 'Estamos disponibles para atender sus inquietudes y proporcionarle soluciones rápidas y efectivas.',
        subjet1: 'Contacto',
        subjet2: 'Inversiones',
        subjet3: 'Soluciones no financieras',
        subjet4: 'Proveduria o alianzas comerciales',
        form_name: 'Nombre completo',
        form_subject: 'Asunto',
        form_phone: 'Teléfono',
        form_email: 'Email',
        form_message: 'Mensaje',
        btn_send: "Enviar",
        error_title: "Campos obligatorios",
        error_msg: "Los campos marcados son requeridos.",
        msg_title: "Mensaje enviado",
        msg_success: "El mensaje fue enviado, en breve un asesor se contactará con UD."
    }
}

// Crear una instancia de i18n
const i18n = createI18n({
    locale: 'es', // idioma por defecto
    messages
})

export default i18n
