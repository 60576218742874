<template>
  <v-card class="panel-nav" color="primary" width="100%" variant="flat">
    <header v-if="myScroll">
      <v-row no-gutters align="center" justify="center" style="height: 70px;">
        <v-col class="nav-container" cols="9" md="8">
          <div>
            <ul class="nav-menu">
              <li><a href="#about">{{ $t('we') }}</a></li>
              <li><a href="#values">{{ $t('values') }}</a></li>
              <li><a href="#companies">{{ $t('companies') }}</a></li>
              <li><a href="#invest">{{ $t('invest') }}</a></li>
              <li><a href="#contact">{{ $t('contact') }}</a></li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </header>
    <header v-else>
      <div>
        <div>
          <v-row no-gutters align="center" justify="center">
            <v-col cols="2" md="2" class="d-flex justify-center">

              <img :src="logo" alt="Logo" style="width: 150px;" />
            </v-col>
            <v-col class="nav-container" cols="9" md="8">
              <div>
                <ul class="nav-menu">
                  <li><a href="#about">{{ $t('we') }}</a></li>
                  <li><a href="#values">{{ $t('values') }}</a></li>
                  <li><a href="#companies">{{ $t('companies') }}</a></li>
                  <li><a href="#invest">{{ $t('invest') }}</a></li>
                  <li><a href="#contact">{{ $t('contact') }}</a></li>
                </ul>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </header>
    <v-fab :key="fabPosition" :absolute="fabPosition === 'absolute'" :app="fabPosition === 'fixed'"
      :color="open ? 'tertiary' : 'primary'" :location="fabLocation" size="compac" icon>

      <v-avatar>
        <v-img alt="flag"
          :src="lang == 'es' ? require('@/assets/svg/flag_mxn.svg') : require('@/assets/svg/flag_usa.svg')"></v-img>
      </v-avatar>
      <v-speed-dial v-model="open" :location="menuLocation" :transition="transition" activator="parent">
        <v-btn key="1" color="primary" icon @click="setLanguaje">
          <v-avatar>
            <v-img alt="flag"
              :src="lang == 'es' ? require('@/assets/svg/flag_usa.svg') : require('@/assets/svg/flag_mxn.svg')"></v-img>
          </v-avatar>
        </v-btn>
      </v-speed-dial>
    </v-fab>
  </v-card>
</template>
<script>
import logo from '@/assets/media/general/logo-lt-white.png';
import { useCommonStore } from '@/stores/commons'
import { useI18n } from 'vue-i18n'



export default {
  props: {
    scroll: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    scroll: {
      handler() {
        this.setScroll();
      },
      immediate: true
    }
  },

  data: () => ({
    lang: 'es',
    logo: logo,
    open: false,
    myScroll: false,
    fabPosition: 'fixed',
    menuLocation: 'bottom center',
    fabLocation: 'top right',
    transition: 'fade-transition',
  }),
  methods: {
    setScroll() {
      this.myScroll = this.scroll
    },
    setLanguaje() {
      this.lang = this.lang == 'es' ? 'en' : 'es'
      const store = useCommonStore()
      store.setLanguage(this.lang)
      // const { locale } = useI18n()
      const { locale } = this.$i18n
      // locale.value = this.lang 
      this.$i18n.locale = this.lang
    }
  }

}
</script>
<style scoped>
.panel-nav {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  padding-top: 0;
  padding-bottom: 5px;
  background-color: #020202;
  box-shadow: 0 0 6px 0 #333;
  opacity: 0.7;
  z-index: 1000;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
}

.nav-container {
  width: 100%;
  /* background-color: #333; Color de fondo del contenedor del menú */
  display: flex;
  /* Para habilitar Flexbox */
  align-items: center;
  /* Centrar verticalmente */
  justify-content: center;
  /* Centrar horizontalmente (opcional) */
}

.nav-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  /* Para hacer que los elementos de la lista se alineen horizontalmente */
  /* justify-content: space-around; Espaciar uniformemente los elementos dentro del contenedor */
}

.nav-menu li {
  margin: 0;
}

.nav-menu a {
  display: block;
  color: white;
  text-align: center;
  padding: 4px 12px;
  text-decoration: none;
  /* Quitar el subrayado */
}

.nav-menu a:hover {
  color: #3d75a5;
  /* Color de fondo cuando se pasa el ratón por encima */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active en versiones anteriores de Vue */
  {
  opacity: 0;
}
</style>