<template>
  <v-col class="pa-2" v-for="(ds, i) in ourValues" :key="i" cols="12" md="4">
    <v-card class="pa-2" color="rgb(61 117 165 / 34%)">
      <div class="single-service">
        <div class="service-icon text-h5">
          <v-img :src="require('@/assets/images/logo-tr.png')" width="200" contain></v-img>
        </div>
        <div class="cont-ic">
          <h3>
            <a>{{ ds.title }}</a>
          </h3>
          <p class="pa-5 text-white" v-if="ds.description">
            {{ ds.description }}
          </p>
          <ul v-if="ds.list && ds.list.length > 0" class="pa-5">
            <li v-for="(l, i) in ds.list" :key="i">
              {{ l }}
            </li>
          </ul>
          <div class="bg-ic">
            <i :class="ds.icon"></i>
          </div>
        </div>


      </div>
    </v-card>

  </v-col>



</template>

<script>
export default {
  name: "CardService",
  props: {
        lng: {
            type: String,
            default: '-'
        },
    },
    watch: {
        lng: {
            handler() {
                this.setValues();
            },
            immediate: true
        }
    },
  data:()=>({
    ourValues: []
  }),
  methods:{
    setValues() {
      this.ourValues = [
        {
          id: 1,
          title: this.$t('title_1'),
          description: this.$t('text_val1'),
        },
        {
          id: 2,
          title: this.$t('title_2'),
          description: this.$t('text_val2'),
        },
        {
          id: 4,
          title: this.$t('title_3'),
          description: this.$t('text_val3'),
        }
      ]
    },

  }
};
</script>

<style scoped>
.single-service {
  height: 300px;
  background: #023859 none repeat scroll 0 0;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 30px;
  margin-top: 45px;
  padding: 40px 15px 30px;
  position: relative;
  text-align: center;

  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.single-service .service-icon {
  background-color: #fff;
  border-radius: 50%;
  box-sizing: border-box;
  height: 80px;
  left: 0;
  line-height: 80px;
  margin: -42px auto 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 80px;
}

.single-service .service-icon i:before {
  font-size: 30px;
  margin-left: 0;
  color: #3d75a5;
}

.single-service:before {
  background: #3d75a5 none repeat scroll 0 0;
  content: "";
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 6px;
}

.single-service:after {
  background: #3d75a5 none repeat scroll 0 0;
  content: "";
  height: 6px;
  left: 0;
  position: absolute;
  top: 0;
  width: 60px;
}

.single-service>h3 {
  font-size: 19px;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #333;
}

.single-service>h3>a:hover {
  color: #ffffff;
}

.cont-ic {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.bg-ic {
  color: rgba(255, 255, 255, 0.97);
  position: absolute;
  left: -10px;
  bottom: -6px;
  font-size: 90px;
  opacity: 0.1;
}

ul {
  list-style-image: url('@/assets/images/chevron-right.png');
  z-index: 20;
}

li {
  text-align: justify !important;
  color: #aaaaaa;
}

li:last-child {
  border-left: 0px solid #000000;
}
</style>