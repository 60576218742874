import { defineStore } from 'pinia'
import repositoryService from '@/stores/services/repository.service'

const PREFIX = 'bc_group_services/';

export const useContactStore = defineStore("Contact", {
    state: () => ({
        succesVal: false,
    }),

    actions: {
        sender(data) {
            try {
                console.log("envio de mensaje")
                let url = `${PREFIX}${data.entity}`
                console.log("url: ", url)
                return repositoryService.post(data.item, url).then(
                    response => {
                        return Promise.resolve(response.status)
                    },
                    (e) => {
                        console.error(error);
                        return Promise.reject(false)
                    }
                );
            } catch (error) {
                console.log(error)
            }
        },
    },
})