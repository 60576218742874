import { defineStore } from 'pinia'
import repositoryService from '@/stores/services/repository.service'

const PREFIX = 'bc_group_services/';

export const usePdfStore = defineStore("pdf", {
    state: () => ({
        pdfFile: {},
    }),

    actions: {
        getPdf(data) {
            try {
                 let url = `${PREFIX}pdf/${data}`
                return repositoryService.get(url).then(
                    response => {
                        this.pdfFile = response.data
                        return Promise.resolve(response.status)
                    },
                    (e) => {
                        console.error(error);
                        return Promise.reject(false)
                    }
                );
            } catch (error) {
                console.log(error)
            }
        },
    },
})
// import { defineStore } from 'pinia'
// import repositoryService from '@/stores/services/repository.service'

// const PREFIX = 'bc_group_services/';

// export const usePdfStore = defineStore("pdfs", {
//     state: () => ({
//         pdfFile: null,
//     }),
//     actions: {
//         getFile(file) {
//             try {
//                  let url = `${PREFIX}pdf/${file}`
//                 return repositoryService.get(url).then((response)=>{
//                     console.log('archivo: ',response)
//                     this.pdfFile = response.data
//                 }),()=>({})
//             } catch (error) {
                
//             }
//         },      
//     },
// })