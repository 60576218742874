import { defineStore } from 'pinia'

export const useCommonStore = defineStore("common", {
    state: () => ({
        lng: 'es',
    }),

    actions: {
        setLanguage(ln) {
            try {
                this.lng = ln
            } catch (error) {
                console.log(error)
            }
        },
    },
})