import axios from 'axios';
import CryptoJS from 'crypto-js';

const NAME_APP = process.env.VUE_APP_APPLICATION_NAME;


//const key = CryptoJS.enc.Utf8.parse('1234567890123456'); 
//const plaintext = "YmM0YzRmYzkyLWdSMFU5LTExZWYtOTZlOC1lZTg2ZDI4ZDQ4YzI=";
const key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_ENCRYPTION_KEY); 
const plaintext = process.env.VUE_APP_PUBLIC_KEY

const encrypted = CryptoJS.AES.encrypt(plaintext, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
}).toString();

function generateKeyPublic() {
    return '@xxxxxxxxxxxxxxx|xxxxxxxxxxxxxxx@xxxxxxxxxxxxx4xxx+yxxxhxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
}

//const key = process.env.VUE_APP_ENCRYPTION_KEY;
// const ckey = CryptoJS.AES.encrypt('HOLA MUNDO', key, {
//     mode: CryptoJS.mode.ECB,
//     padding: CryptoJS.pad.Pkcs7
// }).toString();
const access_token = generateKeyPublic()

export default function setup() {
    axios.interceptors.request.use(function (config) {
        config.headers.Authorization = `Bearer ${access_token}`;
        config.headers.tkuuid = encrypted;
        return config
    })

    axios.interceptors.response.use(
        response => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response);
            } else {
                return Promise.reject(response);
            }
        }, error => {
            return Promise.reject(error);
        });
}